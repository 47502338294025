import Swiper from 'swiper';

class OurHistory {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.textSliderEl = this.root.querySelector(
            '[data-history-swiper-text]'
        );
        this.yearSliderEl = this.root.querySelector(
            '[data-history-swiper-year]'
        );

        this.textSlider = null;
        this.yearSlider = null;

        return true;
    }

    init() {
        this.initSliders();
    }

    initSliders() {
        this.textSlider = new Swiper(this.textSliderEl, {
            slidesPerView: 1,
        });

        this.yearSlider = new Swiper(this.yearSliderEl, {
            slidesPerView: 3,
            direction: 'horizontal',
            spaceBetween: 16,
            loop: false,
            slideToClickedSlide: true,
            breakpoints: {
                992: {
                    direction: 'vertical',
                    slidesPerView: 4,
                    spaceBetween: 0,
                    //centeredSlides: true,
                },
            },
        });

        this.yearSlider.on('click', swiper => {
            const { clickedIndex } = swiper;
            this.yearSlider.slideTo(clickedIndex);
            this.textSlider.slideTo(clickedIndex);
        });
    }
}

function initHistory() {
    const sections = document.querySelectorAll('[data-history]');
    if (!sections) return;

    sections.forEach(item => new OurHistory(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initHistory();
} else {
    document.addEventListener('DOMContentLoaded', initHistory);
}
